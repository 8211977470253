const search = location.search;

$(() => {
	$(window)
		.off("scroll")
		.on("scroll", function () {
			let threshold = $("header").height();
			// threshold is, for now, the height of the header

			if ($(this).scrollTop() > threshold) {
				$("logo .logo_logo").hide();
				$("logo .logo_text").show();
			} else {
				$("logo .logo_text").hide();
				$("logo .logo_logo").show();
			}
			// LOGO SWITCHER
		});
	// scroll spy

	if (search) {
		// preserve querystrings with lang switches

		$("#language_drop a").each(function () {
			$(this).attr("href", `${$(this).attr("href")}${search}`);
		});
	}

	let stored_cart = localStorage.getItem("newsroom_cart");
	window.cart = stored_cart ? JSON.parse(stored_cart) : {};

	const mobileMax = 768;
	$(window).on("resize", function () {
		window.isMobile = $(window).width() <= mobileMax;
	});
	$(window).trigger("resize");
	// set global mobile resize checker

	async function render() {
		try {
			const response = await handleAuth();
			updateCart();
			if (window.renderContent) {
				window.renderContent(response);
			}
		} catch (err) {
			console.error("Error updating cart or authenticating user (global.js):", JSON.stringify(err));
		}
	}
	render();
});
// things to do on every page load
